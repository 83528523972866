@tailwind base;
@tailwind components;
@tailwind utilities;

.article-body {
  p {
    a {
      @apply text-blue-700 underline;
    }
  }

  li {
    @apply text-gray-800;
  }

  li::marker {
    @apply text-gray-800;
  }

  figure {
    img {
      @apply w-full max-w-xl;
    }

    figcaption {
      @apply mt-0 bg-gray-200 p-1 text-center text-sm;
    }

    @apply mx-auto my-2 w-fit;
  }

  table {
    tr {
      @apply p-3;
    }

    td {
      @apply p-3;
    }

    @apply m-0 table-auto border border-gray-200;
  }

  hr {
    @apply my-6 border-gray-400;
  }

  code {
    @apply text-sm;
  }

  blockquote {
    p {
      @apply py-3
    }
    p::before, p::after {
      @apply hidden
    }
    @apply bg-gray-100 mx-4 border-l-indigo-600
  }

  @apply mt-4 text-base leading-8 text-gray-800 md:mt-6 md:text-xl md:leading-9;
}

.article-container:hover {
  .headline {
    @apply text-indigo-700;
  }

  @apply cursor-pointer;
}
.share-icon {
  @apply !h-10 !w-10 sm:h-max sm:w-max
}
.adv-btn {
  @apply mt-2 w-full cursor-pointer px-2 py-3 md:py-4 text-center text-lg md:text-xl font-bold transition delay-100 duration-300 ease-in-out hover:scale-95
}
.adv-btn-red {
  @apply bg-red-600 text-white hover:bg-red-700
}
.adv-btn-blue {
  @apply bg-blue-800 text-white hover:bg-blue-900
}
.adv-btn-indigo {
  @apply bg-indigo-600 text-white hover:bg-indigo-700
}
.adv-btn-gray {
  @apply bg-gray-200 text-black hover:bg-gray-300
}
